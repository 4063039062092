import React from 'react';
import logoFooter from '../images/logo-footer.png';

const Footer = () => {
  return(
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img src={logoFooter} alt="Lavazza"/>
            <h6>2019 LAVAZZA. Wszelkie Prawa Zastrzeżone</h6>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;