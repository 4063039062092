import React, { useState } from 'react';
import Slider from "react-slick";
import img1 from '../images/img-1.png'
import img2 from '../images/img-2.png'
import img3 from '../images/img-3.png'
import img4 from '../images/img-4.png'
import img5 from '../images/img-5.png'
import ArrowButton from '../images/arrow-buttonpng.png';
import done from '../images/done.png';
import arrow from '../images/arrow.png';
import arrow2 from '../images/arrow-2.png';
import select from '../images/select.png';
/** @jsx jsx */
import { jsx } from '@emotion/core'

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className='next'
      css={{
        ':before': {
          content: `url(${arrow})`
        }
        }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className='prev'
      css={{
        ':before': {
          content: `url(${arrow2})`,
        }
        }}
      onClick={onClick}
    />
  );
}

const Form = () => {

  const [isActive1, setActive1] = useState(false);
  const [isActive2, setActive2] = useState(false);
  const [isActive3, setActive3] = useState(false);
  const [ThanksVisible, setVisible] = useState(false);
  const [ButtonText, setButtonText] = useState('Wyślij');



  const [activeCoffe, setActiveCoffe] = useState('')

  const [items] = useState(
    [
      {
        name: "Black coffee mug",
        src: img1
      },
      {
        name: "Espresso",
        src: img2
      },
      {
        name: "Carmecita",
        src: img3
      },
      {
        name: "Cappuccino",
        src: img4
      },
      {
        name: "Americana Papermug",
        src: img5
      },
      {
        name: "Black coffee mug",
        src: img1
      },
      {
        name: "Espresso",
        src: img2
      },
      {
        name: "Carmecita",
        src: img3
      },
      {
        name: "Cappuccino",
        src: img4
      },
      {
        name: "Americana Papermug",
        src: img5
      }
    ]
  )

  const [nameVal, setNameVal] = useState('');
  const [emailVal, setEmailVal] = useState('');
  const [officeVal, setOfficeVal] = useState('');
  const [ruleVal, setRuleVal] = useState(false);
  
  const inputActive = fn => {       
    fn(true)
  }

  const inputInactive = (val, fn) => {
    console.log(val.length)
    if(val.length > 0) {
      fn(true)
    }
    else {
      fn(false)
    }
  }

  async function submitForm(e) {
    e.preventDefault();
    

    
    const data = `name=${nameVal}
                  &email=${emailVal}
                  &office=${officeVal}
                  &rule=${ruleVal}
                  &coffee=${activeCoffe}`;
 
         const xhr = new XMLHttpRequest();
 
         xhr.open("POST",`https://apilavazza.norddigital.com/`, true);
         xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
         
         if(activeCoffe != 0) {
          setButtonText('Wysyłam...');
          xhr.send(data);
        } else{
            setButtonText('Wybierz kawę')
          }
        
 
         xhr.addEventListener('load', function() {
             setNameVal('');
             setEmailVal('');
             setOfficeVal('');
             setRuleVal('');
             setActiveCoffe(''); 
             setVisible(true);
             setButtonText('Wysłano');

         });
  };

  const settings = {
    dots: false,
    autoplaySpeed: 1500,
    speed: 200,
    autoplay: false,
    nav: true,
    slidesToShow: 5,
    centerMode: true,
    centerPadding: 0,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerMode: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          centerMode: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        }
      }
    ]
  }
  return(
    <section className="container">
      
      <div className="row">
        <div className="col-12">
          <p>Abyście mogli się Państwo o tym przekonać podczas naszego spotkania, prosimy o wybranie <span> z poniższych wizualizacji tej, która najlepiej obrazuje Państwa ulubiony typ kawy.</span></p>
          <p>Poczęstujemy nią Państwa podczas spotkania, zapraszając również do udziału w sesji degustacyjnej poprowadzonej przez specjalistę z Centrum Szkoleniowego Lavazzy w Turynie.</p>
      <Slider {...settings}>
        {items.map(item => (
          <div onClick={() => setActiveCoffe(item.name)} className={item.name === activeCoffe ? 'item active' : 'item'}>
            <img src={item.src} alt={item.name}/>
            <img src={select} className="select" alt={item.name}/>
          </div>
        ))}
      </Slider>
      <hr />
      <div className={"popup " + ( ThanksVisible ? "close" : ' ')}>
        <h2>Dziękujemy za wysłanie zgłoszenia</h2>
      </div>
      <h3>Zgłoszenie</h3>
      <form action="#" type="post" onSubmit={ submitForm }>
        <div className="data-row">
          <label onClick={(e) => inputActive(setActive1)} className={isActive1 ? 'active' : ''}>
            <span>Imię i Nazwisko</span>
            <input 
              type="text" 
              name="name" 
              required
              id="name" 
              autoComplete="off"
              onFocus={ () => inputActive(setActive1)}
              onBlur={ () => {inputInactive(nameVal, setActive1)}}
              onChange={ (e) => setNameVal(e.target.value)} />
          </label>
          <label onClick={() => inputActive(setActive2)} className={isActive2 ? 'active' : ''}>
            <span>Adres e-mail</span>
            <input 
              type="email" 
              name="email" 
              required
              id="email" 
              autoComplete="off"
              onFocus={ () => inputActive(setActive2)}
              onBlur={ () => {inputInactive(emailVal, setActive2)}}
              onChange={ (e) => setEmailVal(e.target.value) } />
          </label>
          <label onClick={() => inputActive(setActive3)} className={isActive3 ? 'active' : ''}>
            <span>Redakcja</span>
            <input 
              type="text" 
              name="office" 
              required
              id="office" 
              autoComplete="off"
              onFocus={ () => inputActive(setActive3)}
              onBlur={ () => {inputInactive(officeVal, setActive3)}}
              onChange={ (e) => setOfficeVal(e.target.value) } />
          </label>
        </div>
        <div className="check-row">
          <label className="check">
            <input required type="checkbox" name="rule" 
            onChange={ (e) => setRuleVal(e.target.checked) }
            css={{
            ':after': {
                background: `#fff`,
            },
            ':before': {
              background: `url(${done})`
            }
            }}
          />
          <span>Wyrażam zgodę na przetwarzanie danych osobowych.</span>
          </label>
          <label className="button">
          <button type="submit">{ButtonText}</button>
          <img src={ArrowButton} alt="arrow"/>
          </label>
        </div>
      </form>
        </div>
      </div>  
    </section>
  )
}

export default Form;