import React from "react"
import logo from '../images/logo.svg';

const Header = () => (
  <header className="container">
    <div className="row">
      <div className="col-12">
        <img src={logo} alt="Lavazza" />
        <h2>ESPRESSO I WIĘCEj</h2>
        <h4>Odkryj smak włoskiej kawy w jej wszystkich formach</h4>
        <h3>
        Niezależnie od tego, jakie są nasze kawowe zwyczaje i ulubione sposoby jej parzenia, 
        <span> z Lavazzą zawsze jest to doświadczanie smaku prawdziwie włoskiej kawy.</span>
        </h3>
        <hr />
      </div>
    </div>
  </header>
)


export default Header
